<template>
  <div>
    <div
      v-if="checkLoginRole() === RoleEnum.SuperAdmin ? true : checkAccess.Add"
      class="text-right position-relative mb-2"
    >
      <b-button class="addBtn" variant="primary" @click="$router.push('add')">
        <span class="text-nowrap">Add Location</span>
      </b-button>
    </div>
    <confirm-delete
      ref="modal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deleteLocation"
    />
    <div v-if="!items.length" class="demo-spacing-0">
      <b-alert variant="primary" show>
        <div class="alert-body">
          <span v-if="!items.length && !contentLoading"><strong>{{ NotFound.LOCATION_NOT_FOUND }}</strong></span>
        </div>
      </b-alert>
    </div>
    <Loader v-if="contentLoading" />

    <draggable
      v-model="items"
      :force-fallback="true"
      ghost-class="ghost"
      animation="200"
      @change="changed($event)"
    >
      <transition-group class="row">
        <div v-for="item in items" :key="item.id" class="col-md-3">
          <div class="card theme-card theme_card_box">
            <img :src="item.image" alt="">
            <span class="theme-is-active" :class="item.isActive ? 'bg-success' : 'bg-danger'">{{item.isActive ? 'Active' : 'In-Active'}}</span>
            <span class="theme-name">{{ item.name }}</span>
            <b-dropdown
              v-if="checkAccess.Edit || checkAccess.delete"
              style="position: absolute; bottom: 0; right: 0;"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  stroke="white"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="
                  checkLoginRole() === RoleEnum.SuperAdmin
                    ? true
                    : checkAccess.Edit
                "
                :to="{ name: 'location-edit', params: { id: item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  checkLoginRole() === RoleEnum.SuperAdmin
                    ? true
                    : checkAccess.delete
                "
                @click="openModal(item.id, item.name)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BButton, BAlert
} from "bootstrap-vue";
// import vSelect from 'vue-select'
import {
  resolveUserRoleIcon,
  resolveUserRoleVariant,
  resolveUserStatusVariant,
  resolveUserRoleName,
  resolveUserStatusName,
  perPageOptions,
  perPage,
  currentPage,
  RoleEnum,
  statusFilter,
  resConditionCheck,
  deleteConfirmMsg,
  NotFound
} from "@/helpers/constant";
import accessRightCheck from "@/helpers/accessRightCheck";
import checkLoginRole from "@/helpers/checkLoginRole";
import locationService from "@/services/banner-management/location/location.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from "@/services/errorHandler";
import draggable from "vuedraggable";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import ConfirmDelete from "../../../confirm-delete/ConfirmDelete.vue";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BButton,
    draggable,
    ConfirmDelete,
    BAlert,
    Loader
  },

  data() {
    return {
      contentLoading: true,
      searchQuery: "",
      sortBy: "",
      totalLocations: 0,
      isSortDirDesc: true,
      items: [],
      startIndex: 0,
      endIndex: 0,
      tableColumns: [{ key: "order" }, { key: "theme" }, { key: "actions" }],
      currentPage,
      perPage,
      perPageOptions,
      resolveUserRoleIcon,
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      resolveUserRoleName,
      resolveUserStatusName,
      resConditionCheck,
      statusFilter,
      deleteMsg: "",
      titleMsg: "",
      locationId: "",
      checkAccess: {},
      RoleEnum,
      NotFound,
      checkLoginRole,
      oldIndex: "",
      newIndex: ""
    };
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    this.getLocationListing();
  },

  methods: {
    getLocationListing() {
      this.contentLoading = true
      locationService
        .getLocationListing()
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data;
            this.totalLocations = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false
          if (error.response.status === 404) {
            this.items = [];
            this.totalLocations = 0;
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: errorData,
            //     icon: "X-CircleIcon",
            //     variant: "danger"
            //   }
            // });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    openModal(id, name) {
      this.titleMsg = deleteConfirmMsg;
      this.deleteMsg = `Are you sure you want to delete ${name} location?`;
      this.locationId = id;
      this.$refs.modal.show();
    },

    deleteLocation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.modal.loading = true;
      if (this.locationId) {
        locationService
          .deleteLocation(this.locationId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$refs.modal.loading = false;
              this.$refs.modal.toggleModal = false;
              this.items = this.items.filter(
                item => item.id !== this.locationId
              );
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },

    async changed(evt) {
      const {
        oldIndex,
        newIndex,
        element: { id }
      } = evt.moved;

      const payload = {
        previous_index: oldIndex + 1,
        current_index: newIndex + 1
      };
      try {
        // eslint-disable-next-line no-unused-vars
        const res = await locationService.reorderLocation(id, payload);
      } catch (error) {
        const errorData = errorResponseHandler(error);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.theme-card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 180px;
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
}
.theme-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.ghost {
  background: rgb(129, 125, 125);
  border: dotted 3px #fff;
}
.ghost:before {
  content: "Drag Here";
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 40%;
  left: 35%;
  font-family: "Times New Roman", Times, serif;
}
.theme-card .theme-name {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  font-size: 14px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.addBtn {
  position: absolute;
  right: 0;
  top: -60px;
}
.ghost .theme-card {
  opacity: 0;
}

.theme-card .theme-is-active {
  position: absolute;
  max-width: 50%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  color: #fff;
  text-align: center;
  padding: 0px 5px;
  font-size: 12px;
  // opacity: 0.8;
  box-shadow: -3px 5px 8px 2px rgb(0 0 0 / 60%);
  font-weight: bold;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
